<template>
  <div class="creationSites">
    <div class="vid">
      <video id="video" src="../assets/background3.mp4" autoplay="true" preload="auto" loop muted></video>
    </div>

    <h2><span class="orange">Creation</span> de sites</h2>

    <div class="phraseDebut">
      <p>Nous proposons des solutions économiques pour la création de votre site internet.</p>
      <p>Trois types de formules s'offrent à vous :</p>
    </div>

    <div class="carousel">
      <input type="radio" name="slider" id="s1">
      <input type="radio" name="slider" id="s2" checked>
      <input type="radio" name="slider" id="s3">

      <label for="s1" id="slide1">
        <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="piggy-bank" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-piggy-bank fa-w-18" style="--fa-secondary-opacity:0.5;"><g class="fa-group"><path fill="currentColor" d="M560 224h-29.51a159.88 159.88 0 0 0-37.38-52.46L512 96h-32c-29.4 0-55.39 13.5-73 34.32-7.57-1.1-15.12-2.32-23-2.32H256c-94.82 0-160 78.88-160 160a159.75 159.75 0 0 0 64 128v80a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-48h128v48a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-80.72A160.37 160.37 0 0 0 511.28 384H560a16 16 0 0 0 16-16V240a16 16 0 0 0-16-16zm-128 64a16 16 0 1 1 16-16 16 16 0 0 1-16 16z" class="fa-secondary"></path><path fill="currentColor" d="M51.26 255.52a24 24 0 0 1-18.74-28.3C34.74 215.82 45.4 208 57 208h1a6 6 0 0 0 6-6v-20a6 6 0 0 0-6-6C29.5 176 4.1 196.4.47 224.62a54.64 54.64 0 0 0-.47 7.23A56.08 56.08 0 0 0 56 288h40a155.05 155.05 0 0 1 3.37-32H56a23.63 23.63 0 0 1-4.74-.48zM432 256a16 16 0 1 0 16 16 16 16 0 0 0-16-16zM306.5 0a96 96 0 0 0-88.81 132.51A162.64 162.64 0 0 1 256 128h128a104.31 104.31 0 0 1 12.71.88A96.06 96.06 0 0 0 306.5 0z" class="fa-primary"></path></g></svg>
        <p class="nomformule">Formule Essentielle</p>
        <p class="contenu">La formule essentielle vous garantit le meilleur rapport qualité prix pour les petits budgets. Découvrez nos différents modèles parmi nos exemples de créations. Ce sont des modèles préétablis, personnalisables selon votre activité.</p>
        <p class="prix">À partir de 499€</p>
        <router-link class="btnCarte1" to="/formuleEssentielle">En savoir plus</router-link>
      </label>

      <label for="s2" id="slide2">
        <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="gem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-gem fa-w-18" style="--fa-secondary-opacity:0.5;"><g class="fa-group"><path fill="currentColor" d="M100.7 192H0l218.7 255a3 3 0 0 0 5-3.3zm374.6 0l-123 251.7a3 3 0 0 0 5 3.2L576 192zm-327.1 0l137.1 318.2a3 3 0 0 0 5.5 0l137-318.2z" class="fa-secondary"></path><path fill="currentColor" d="M90.5 0L0 160h101.1L170.3 0zm395 0h-79.8l69.2 160H576zm-267 0l-69.2 160h277.4L357.5 0z" class="fa-primary"></path></g></svg>
        <p class="nomformule">Formule Premium</p>
        <p class="contenu">Profitez de fonctionnalités avancées, ainsi que d'effets dynamiques à un prix abordable. Découvrez nos différents modèles parmi nos exemples de créations. Ce sont des modèles préétablis, personnalisables selon votre activité.</p>
        <p class="prix">À partir de 999€</p>
        <router-link class="btnCarte2" to="/formulePremium">En savoir plus</router-link>
      </label>

      <label for="s3" id="slide3">
        <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="pencil-ruler" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-pencil-ruler fa-w-16" style="--fa-secondary-opacity:0.5;"><g class="fa-group"><path fill="currentColor" d="M138.25 127.05a7.92 7.92 0 0 1-11.2 0l-11.21-11.21a7.92 7.92 0 0 1 0-11.21L177.5 43 143.87 9.3A31.73 31.73 0 0 0 99 9.3L9.29 99a31.74 31.74 0 0 0 0 44.86l100.17 100.19L244 109.49l-44.08-44.12zm364.46 241.1l-33.63-33.64-61.68 61.68a7.92 7.92 0 0 1-11.21 0L385 385a7.92 7.92 0 0 1 0-11.21l61.68-61.68L402.52 268 267.94 402.51l100.21 100.2a31.7 31.7 0 0 0 44.85 0L502.71 413a31.72 31.72 0 0 0 0-44.85z" class="fa-secondary"></path><path fill="currentColor" d="M497.94 59.32l-45.25-45.25a48.05 48.05 0 0 0-67.95 0l-46 46 113.21 113.2 46-46a48 48 0 0 0-.01-67.95zM19.08 379.68L.33 487.12a21.23 21.23 0 0 0 24.59 24.56l107.45-18.84 296.92-296.93L316.08 82.72z" class="fa-primary"></path></g></svg>
        <p class="nomformule">Formule Sur mesure</p>
        <p class="contenu">La formule sur mesure qui vous permet une plus grande liberté de personnalisation. </p>
        <p class="prix">Prix variable en fonction du contenu</p>
        <router-link class="btnCarte3" to="/formuleSurMesure">En savoir plus</router-link>
      </label>
    </div>

    <div class="phraseFin">
      <p>Tous nos sites sont adaptatifs (responsive web design) afin de mieux correspondre aux différents formats d'écran (ordinateurs, tablettes, smartphones). Afin de garantir des prix les plus attractifs, nous ne proposons pas de sites intégrants des bases de données.</p>
      <router-link class="btnDevis" to="/contact">Demandez un devis !</router-link>
    </div>
    
  </div>
</template>

<style scoped>

.phraseDebut, .phraseFin{
  width: 80%;
  text-align: center;
  color:white;
  font-family: "jura";
  font-size: 25px;
  margin: 20px auto;
}

.btnDevis{
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  margin-top: 30px;
  background-color: rgb(98, 172, 255);
	color: #fff;
	border: #007BFF solid 2px;
	transition: 1s;
}

.btnDevis:hover{
  color: #007BFF;
  border: rgb(98, 172, 255) solid 2px;
  background-color: #fff;
}

/* Carousel --------------------------------------------------------*/

.carousel{
  width: 100%;
  text-align: center;
  margin-top: 70px;
}

[type=radio] {
  display: none;
}

.carousel label{
  width: 30%;
  min-height: 550px;
  background-color: #fff;
  position: absolute;
  left: 0; right: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
  margin: auto;
  z-index: 0;
  box-shadow: black 0px 5px 5px;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  color:white;
  font-family: "jura";
}

.carousel label svg{
  font-size: 70px;
  margin: 30px auto;
}

.carousel label .nomformule{
  font-size: 35px;
  margin-bottom: 30px;
}

.carousel label .contenu{
  font-size: 20px;
   margin-bottom: 50px;
}

.carousel label .prix{
  font-size: 15px;
}

.carousel label a{
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  margin-top: 30px;
}

.carousel label a:hover{
  opacity:0.5;
}

.btnCarte1 {
	background-color: rgb(98, 172, 255);
	color: #fff;
	border: #007BFF solid 2px;
	transition: 1s;
}

.btnCarte2 {
	background-color: rgb(58, 236, 100);
	color: #fff;
	border: #28A745 solid 2px;
	transition: 1s;
}

.btnCarte3 {
	background-color: #fd9024;
	color: #fff;
	border: #d66a00 solid 2px;
	transition: 1s;
}

@media screen and (max-width: 700px) {

  .carousel label{
    width: 60%;
    min-height: inherit;
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 700px) {

  .phraseFin{
    margin: 700px auto 50px auto;
  }

  #slide1{
    transform: translate(-150px,0) scale(0.9);
  }

  #slide3{
    transform: translate(150px,0) scale(0.9);
  }

  #s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3{
  transform: translate(0,0);
  z-index: 1;
  opacity: 0.9;
  filter: blur(0);
}

#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide1{
  transform: translate(150px,0) scale(0.9);
  z-index: 0;
  opacity: 0.7;
  filter: blur(1px);
}

#s1:checked ~ #slide3,
#s3:checked ~ #slide2,
#s2:checked ~ #slide1{
  transform: translate(-150px,0) scale(0.9);
  z-index: 0;
  opacity: 0.7;
  filter: blur(1px);
}
}

#slide1 { background: #007BFF; }
#slide2 { background: #28a745  }
#slide3 { background: #d66a00  }

/* Fin Carousel --------------------------------------------------------*/

</style>

<script>

export default {
  name: 'CreationSites',
  data(){
    return {
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>